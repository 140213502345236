import SolanaIcon from 'assets/icons/solana.svg'
import USDCIcon from 'assets/icons/usdc.svg'

const balanceDropdown = [
	{
		imgUrl: SolanaIcon,
		unit: 'SOL',
		className: '',
	},
	{
		imgUrl: USDCIcon,
		unit: 'USDC',
		className: 'full',
	},
]

export default balanceDropdown
